import {
  faArrowLeft,
  faChartBar,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { renderDepositCell, renderDisbursementCell, renderOrganization, renderShortId } from "utils/orderHelpers";
import { IOrderStats } from "../services/interfaces/OrderStats";
import { ITransaction } from "../services/interfaces/Transaction";
import { IUser } from "../services/interfaces/User";
import { useStores } from "../stores/index";
import "../styles/UserDetails.scss";
import { renderCountryFlag } from '../utils/countryFlagHelper';
import { formatCurrency, getCountryNameFromCode } from '../utils/formatters';
import { renderKycLevel } from '../utils/kycHelpers';
import BadgeComponent from "./BadgeComponent";
import { formatDate } from "utils/dateHelpers";
import { getStatusIcon, renderOrderStatus, renderTxType } from "utils/transactionHelpers";
import { renderPaymentMethodIcon } from '../utils/paymentMethodHelpers';
import UserUpdateHistory from './TimelineHistory';
import { FaHistory } from 'react-icons/fa';

// Add this type definition at the top of the file, after the imports
type CryptoStats = {
  crypto: string;
  buy: number;
  sell: number;
  pay: number;
  total: number;
};



const UserDetails: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { appStore } = useStores();
  const [user, setUser] = useState<IUser | null>(null);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setIsLoading(true);
          const userData = await appStore.fetchUserDetails(id);
          setUser(userData);
          const userTransactions = await appStore.fetchUserTransactions(id);
          setTransactions(userTransactions.orders);
          setHasMore(userTransactions.orders.length === 10); // Assuming 10 is the default limit
        } catch (err) {
          setError("Failed to fetch user details. Please try again later.");
          console.error("Error fetching user details:", err);
        } finally {
          setIsLoading(false);
        }
      } else {
        setError("User ID is missing");
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, appStore]);

  const loadMoreTransactions = async () => {
    if (!id || transactions.length === 0) return;

    setIsLoadingMore(true);
    try {
      const lastOrderDate = transactions[transactions.length - 1].createdAt;
      const moreTransactions = await appStore.fetchUserTransactions(id, lastOrderDate);
      setTransactions(prevTransactions => [...prevTransactions, ...moreTransactions.orders]);
      setHasMore(moreTransactions.orders.length === 10); // Assuming 10 is the default limit
    } catch (err) {
      console.error("Error fetching more transactions:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const hasOrders = user && user.stats && (
    (user.stats.buyStats?.totals?.totalTransactionCountAll ?? 0) > 0 ||
    (user.stats.sellStats?.totals?.totalTransactionCountAll ?? 0) > 0
  );

  const renderStatistics = (stats: IOrderStats['buyStats'] | IOrderStats['sellStats'] | undefined, title: string) => {
    if (!stats || !stats.totals || stats.totals.totalTransactionCountAll === 0) {
      return (
        <Alert variant="info">
          <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
          No {title.toLowerCase()} orders found for this user.
        </Alert>
      );
    }

    return (
      <>
        <h6>{title} Transactions</h6>
        <Table className="mtable">
          <thead>
            <tr>
              <th>Total Amount</th>
              <th>Total Transactions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${stats.totals.totalAmountAll.toFixed(2)}</td>
              <td>{stats.totals.totalTransactionCountAll}</td>
            </tr>
          </tbody>
        </Table>

        <h6>Transactions by cryptocurrency</h6>
        <Table  hover>
          <thead>
            <tr>
              <th>Cryptocurrency</th>
              <th>Total Amount</th>
              <th>Transaction Count</th>
            </tr>
          </thead>
          <tbody>
            {stats.statsByCryptoCurrency.map((stat, index) => (
              <tr key={index}>
                <td>{stat.cryptoCurrency}</td>
                <td>${stat.totalAmount.toFixed(2)}</td>
                <td>{stat.transactionCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };

  const renderEmptyTransactionsCard = () => (
    <Card className="text-center mb-4">
      <Card.Body>
        <FontAwesomeIcon icon={faChartBar} size="4x" className="mb-4 text-muted" />
        <Card.Title>No Transactions</Card.Title>
        <Card.Text>
          This user hasn't made any transactions yet.
        </Card.Text>
      </Card.Body>
    </Card>
  );

  const renderCombinedStatistics = (stats: IOrderStats | undefined) => {
    if (!stats || (!stats.buyStats && !stats.sellStats && !stats.payStats)) {
      return renderEmptyTransactionsCard();
    }

    const combinedStats: { [key: string]: { buy: number, sell: number, pay: number, total: number } } = {};

    const processStats = (data: IOrderStats['buyStats'] | IOrderStats['sellStats'] | IOrderStats['payStats'] | undefined, type: 'buy' | 'sell' | 'pay') => {
      if (data && data.statsByCryptoCurrency) {
        data.statsByCryptoCurrency.forEach(stat => {
          if (!combinedStats[stat.cryptoCurrency]) {
            combinedStats[stat.cryptoCurrency] = { buy: 0, sell: 0, pay: 0, total: 0 };
          }
          combinedStats[stat.cryptoCurrency][type] = stat.totalAmount;
          combinedStats[stat.cryptoCurrency].total += stat.totalAmount;
        });
      }
    };

    processStats(stats.buyStats, 'buy');
    processStats(stats.sellStats, 'sell');
    processStats(stats.payStats, 'pay');

    const sortedStats: CryptoStats[] = Object.entries(combinedStats)
      .sort(([, a], [, b]) => b.total - a.total)
      .map(([crypto, amounts]) => ({ crypto, ...amounts }));

    const chartData = sortedStats.map(({ crypto, buy, sell, pay }) => ({
      name: crypto,
      Buy: buy,
      Sell: sell,
      Pay: pay,
    }));

    const CustomTooltip = ({ active, payload, label }: any) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="label">{`${label}`}</p>
            {payload.map((pld: any) => (
              <p key={pld.name} style={{ color: pld.color }}>
                {`${pld.name}: ${formatCurrency(pld.value)}`}
              </p>
            ))}
          </div>
        );
      }
      return null;
    };

    const totalBuy = stats.buyStats?.totals?.totalAmountAll || 0;
    const totalSell = stats.sellStats?.totals?.totalAmountAll || 0;
    const totalPay = stats.payStats?.totals?.totalAmountAll || 0;

    const txCountBuy = stats.buyStats?.totals?.totalTransactionCountAll || 0;
    const txCountSell = stats.sellStats?.totals?.totalTransactionCountAll || 0;
    const txCountPay = stats.payStats?.totals?.totalTransactionCountAll || 0;

    const COLORS = ['#3066DB', '#7757F9', '#00C49F'];

    return (
      <>
        <div className="chart-card mb-4">
          <Row className="mb-4">
            <Col md={4}>
              <div className="stats-card buy-card">
                <h3>Buy</h3>
                <p className="stats-value">{formatCurrency(totalBuy)}</p>
                <p className="stats-subvalue">{txCountBuy} transactions</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="stats-card sell-card">
                <h3>Sell</h3>
                <p className="stats-value">{formatCurrency(totalSell)}</p>
                <p className="stats-subvalue">{txCountSell} transactions</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="stats-card pay-card">
                <h3>Pay</h3>
                <p className="stats-value">{formatCurrency(totalPay)}</p>
                <p className="stats-subvalue">{txCountPay} transactions</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="chart-card">
          <h5>Transactions by Cryptocurrency</h5>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData}>
              <XAxis dataKey="name" />
              <YAxis tickFormatter={(value) => formatCurrency(value)} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="Buy" fill={COLORS[0]} />
              <Bar dataKey="Sell" fill={COLORS[1]} />
              <Bar dataKey="Pay" fill={COLORS[2]} />
            </BarChart>
          </ResponsiveContainer>
          <Table hover className="mt-4">
            <thead>
              <tr>
                <th>Cryptocurrency</th>
                <th>Buy</th>
                <th>Sell</th>
                <th>Pay</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {sortedStats.map(({ crypto, buy, sell, pay, total }) => (
                <tr key={crypto}>
                  <td>{crypto}</td>
                  <td className={buy === 0 ? 'text-muted' : ''}>
                    {formatCurrency(buy)}
                  </td>
                  <td className={sell === 0 ? 'text-muted' : ''}>
                    {formatCurrency(sell)}
                  </td>
                  <td className={pay === 0 ? 'text-muted' : ''}>
                    {formatCurrency(pay)}
                  </td>
                  <td className={total === 0 ? 'text-muted' : ''}>
                    {formatCurrency(total)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  const renderTransactionHistory = () => {
    if (transactions.length === 0) {
      return renderEmptyTransactionsCard();
    }

    return (
      <>
        <Table hover responsive>
          <thead>
            <tr>
            <th></th>
            <th>Id</th>
            <th>Date</th>
              <th>Type</th>
              <th>Deposit</th>
              <th>Disbursement</th>
              <th>Organization</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{renderPaymentMethodIcon(transaction)}</td>
                <td>{renderShortId(transaction.id)}</td>
                <td>{formatDate(transaction.createdAt)}</td>
                <td>{renderTxType(transaction.type)}</td>
                <td>{renderDepositCell(transaction)}</td>
                <td>{renderDisbursementCell(transaction)}</td>
                <td>{renderOrganization(transaction.organization)}</td>
                <td>{renderOrderStatus(transaction.status)}</td>
                <td className="text-end">
                  <Link to={`/transactions/${transaction.id}`} className="btn btn-sm btn-outline-primary">
                    Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {hasMore && (
          <div className="text-center mt-4">
            <Button
              variant="outline-primary"
              onClick={loadMoreTransactions}
              disabled={isLoadingMore}
            >
              {isLoadingMore ? 'Loading...' : 'Load More'}
            </Button>
          </div>
        )}
      </>
    );
  };

  if (isLoading) {
    return (
      <div className="user-details-container">
        <div className="content-wrapper">
          <div className="loading-container">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="loading-text">Loading user details...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="user-details-container">
        <div className="content-wrapper">
          <div className="alert alert-danger mt-3">{error}</div>
         
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="user-details-container">
        <div className="content-wrapper">
          <div>No user found</div>
          <Button
            variant="outline-primary"
            className="mt-3"
            onClick={() => navigate("/users")}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back to Users
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="user-details-container">
      <div className="content-wrapper">
        <h1 className="page-title">
          {renderCountryFlag(user._kycCountry)}  {user.email}
        </h1>

        <Row className="mb-4">
          <Col md={4}>
            <div className="order-section mb-4">
              <h2>User Details</h2>
              <hr />
              <Table className="mtable">
                <tbody>
                  <tr>
                    <td><strong>Account status:</strong></td>
                    <td><BadgeComponent status={user.status} /></td>
                  </tr>
                  <tr>
                    <td><strong>KYC status:</strong></td>
                    <td>{renderKycLevel(user)}</td>
                  </tr>
                  <tr>
                    <td><strong>Email:</strong></td>
                    <td>{user.email}</td>
                  </tr>
                  <tr>
                    <td><strong>Country:</strong></td>
                    <td>
                      {getCountryNameFromCode(user._kycCountry)}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>ID:</strong></td>
                    <td>{user._id}</td>
                  </tr>
                  <tr>
                    <td><strong>Created At:</strong></td>
                    <td>{formatDate(user.createdAt)}</td>
                  </tr>
                  <tr>
                    <td><strong>Organization:</strong></td>
                    <td>{renderOrganization(user.organization)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            </Col>
            <Col md={8}>
            {transactions.length > 0 ? (
              <>
                <div className="order-section mb-4">
                  <h2>Transaction Totals</h2>
                  <hr />
                  {renderCombinedStatistics(user?.stats)}
                </div>
                <div className="order-section mb-4">
                  <h2>Transaction History</h2>
                  <hr />
                  {renderTransactionHistory()}
                </div>
              </>
            ) : (
              renderEmptyTransactionsCard()
            )}

            
              
                
                  <Row className="mb-4">
                    <Col>
                      
                        {user && user.updates && user.updates.length > 0 ? (
                          <UserUpdateHistory updates={user.updates} />
                        ) : (
                          <Alert variant="info">Timeline is not available for this user.</Alert>
                        )}
                      
                    </Col>
                  </Row>
                
              
            

          </Col>
        </Row>

      </div>
    </div>
  );
});

export default UserDetails;
