import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Badge, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { FaCheck, FaClock, FaQuestion, FaTimes, FaArrowUp, FaArrowDown, FaArrowRight, FaMonero } from 'react-icons/fa';
import { useStores } from '../stores/index';
import '../styles/Orders.css';
import { getCryptoImageUrl, getPaymentMethodIconUrl } from '../utils/orderUtils';
import { Link, useNavigate } from 'react-router-dom';
import { ITransaction } from 'services/interfaces/Transaction';
import { IOrderStats } from 'services/interfaces/OrderStats';
import { IOrganization } from 'services/interfaces/Organization';
import { formatCurrency, formatPaymentMethod, getCountryNameFromCode } from 'utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faIdBadge, faIdCard, faIdCardClip, faInfo, faSearch } from '@fortawesome/free-solid-svg-icons';
import BadgeComponent from './BadgeComponent'; // Import the new BadgeComponent
import KadoIcon from './KadoIcon';
import { renderTxType, renderOrderStatus, getStatusIcon } from '../utils/transactionHelpers';
import { formatDate } from '../utils/dateHelpers';
import { renderDepositCell, renderDisbursementCell, renderOrganization, renderShortId } from '../utils/orderHelpers';
import { renderCountryFlag } from 'utils/countryFlagHelper';
import { renderPaymentMethodIcon } from 'utils/paymentMethodHelpers';



const shortenAddress = (address: string, chars = 4) => {
  return `${address.slice(0, chars-2)}...${address.slice(-chars)}`;
};

const Orders: React.FC = observer(() => {
  const { appStore } = useStores();
  const [orderStats, setOrderStats] = useState<IOrderStats | null>(null);
  const [orders, setOrders] = useState<ITransaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const itemsPerPage = 50;
  const navigate = useNavigate();

  const { period, status, orgId, orderId, txType } = appStore.filterState;

  useEffect(() => {
    fetchOrdersAndStats();
    fetchOrganizations();
  }, [period, status, orgId, txType, orderId]);

  useEffect(() => {
    if (organizations.length === 1 && !orgId) {
      appStore.setFilterState({ orgId: organizations[0]._id });
    }
  }, [organizations]);

  const calculateDateRange = (periodValue: string): { dateFrom: string, dateTo: string } => {
    const now = new Date();
    now.setSeconds(59, 999); // Set to end of the current minute
    let dateFrom = new Date(now);
    dateFrom.setSeconds(0, 0); // Set to start of the current minute

    switch (periodValue) {
      case 'last24hours':
        dateFrom.setHours(now.getHours() - 24);
        break;
      case 'last72hours':
        dateFrom.setHours(now.getHours() - 72);
        break;
      case 'last7days':
        dateFrom.setDate(now.getDate() - 7);
        break;
      case 'last14days':
        dateFrom.setDate(now.getDate() - 14);
        break;
      case 'last30days':
        dateFrom.setDate(now.getDate() - 30);
        break;
      case 'lastYear':
        dateFrom.setFullYear(now.getFullYear() - 1);
        break;
      case 'all':
        dateFrom = new Date(0); // Beginning of time
        break;
    }

    return {
      dateFrom: dateFrom.toISOString(),
      dateTo: now.toISOString()
    };
  };

  const fetchOrdersAndStats = async (resetResults: boolean = false) => {
    setIsLoading(true);
    try {
      const { dateFrom, dateTo } = calculateDateRange(period);
      const lastOrderDate = orders.length > 0 ? orders[orders.length - 1].createdAt : null;
      
      const queryParams = new URLSearchParams({
        limit: itemsPerPage.toString(),
        dateFrom,
        dateTo,
        status,
        organizationId: orgId,
        orderId,
      });

      if (!resetResults && lastOrderDate) {
        queryParams.set('dateTo', lastOrderDate);
      }
      if (txType !== 'ALL') {
        queryParams.set('txType', txType);
      }
      

      const { orders: fetchedOrders, stats } = await appStore.fetchTransactions(
        `?${queryParams.toString()}`
      );
      
      
      if (resetResults) {
        setOrders(fetchedOrders);
      } else {
        setOrders(prevOrders => [...prevOrders, ...fetchedOrders]);
      }
      
      setOrderStats(stats);
      const moreAvailable = fetchedOrders.length >1;
      setHasMore(moreAvailable);
      setError(null);
    } catch (err) {
      setError('Failed to fetch orders and stats. Please try again later.');
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrganizations = async () => {
    const fetchedOrganizations = await appStore.fetchOrganizations();
    setOrganizations(fetchedOrganizations);
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    appStore.setFilterState({ period: event.target.value });
    resetFilters();
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    appStore.setFilterState({ status: event.target.value });
    resetFilters();
  };

  const handleOrgIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    appStore.setFilterState({ orgId: event.target.value });
    resetFilters();
  };

  const handleOrderIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    appStore.setFilterState({ orderId: event.target.value });
  };

  const handleOrderIdKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  const resetFilters = () => {
    setOrders([]);
    setHasMore(true);
  };

  const handleClearFilters = () => {
    appStore.setFilterState({
      period: 'last30days',
      status: 'ALL',
      orgId: '',
      orderId: '',
      txType: 'ALL',
    });
    applyFilters();
  };

  const applyFilters = () => {
    setOrders([]);
    setHasMore(true);
    fetchOrdersAndStats(true);
  };

  
  const loadMoreOrders = () => {
    fetchOrdersAndStats(false);
  };

  const renderEmptyState = () => (
    <div className="empty-state">
      <h3>No Transactions Found</h3>
      <p>There are no transactions matching your current filters.</p>
      <button className="btn btn-primary" onClick={handleClearFilters}>
        Clear Filters
      </button>
    </div>
  );

  const handleTxTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    appStore.setFilterState({ txType: event.target.value });
    resetFilters();
  };

  const handleRowClick = (orderId: string) => {
    navigate(`/transactions/${orderId}`);
  };

  if (isLoading && orders.length === 0) {
    return (
      <div className="orders-container">
        <div className="content-wrapper">
          <div className="loading-container">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="loading-text">Loading transactions...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger mt-3">{error}</div>;
  }

  return (
    <div className="orders-container">
      <div className="content-wrapper">
        <div className="filter-container">
          <div className="row g-3">
            <div className="col-md-2 col-sm-6">
              <label className="form-label">Organization</label>
              <select className="form-select" value={orgId} onChange={handleOrgIdChange}>
                {organizations.length > 1 && <option value="">All</option>}
                {organizations.map((org) => (
                  <option key={org._id} value={org._id}>{org.name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-2 col-sm-6">
              <label className="form-label">Period</label>
              <select className="form-select" value={period} onChange={handlePeriodChange}>
                <option value="all">All time</option>
                <option value="last24hours">Last 24 hours</option>
                <option value="last72hours">Last 72 hours</option>
                <option value="last7days">Last 7 days</option>
                <option value="last14days">Last 14 days</option>
                <option value="last30days">Last 30 days</option>
                <option value="lastYear">Last year</option>
              </select>
            </div>
            <div className="col-md-2 col-sm-6">
              <label className="form-label">Type</label>
              <select className="form-select" value={txType} onChange={handleTxTypeChange}>
                <option value="ALL">All</option>
                <option value="BUY">Buy</option>
                <option value="SELL">Sell</option>
                <option value="PAY">Pay</option>
              </select>
            </div>
            <div className="col-md-2 col-sm-6">
              <label className="form-label">Status</label>
              <select className="form-select" value={status} onChange={handleStatusChange}>
                <option value="ALL">All</option>
                <option value="SETTLED">Settled</option>
                <option value="PENDING">Pending</option>
                <option value="FAILED">Failed</option>
              </select>
            </div>
            
            <div className="col-md-4 col-sm-6">
              <label className="form-label">Transaction</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by transaction ID"
                  value={orderId}
                  onChange={handleOrderIdChange}
                  onKeyPress={handleOrderIdKeyPress}
                />
                <button className="btn btn-primary" onClick={applyFilters}>
                <FontAwesomeIcon icon={faSearch} className="me-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {orders.length > 0 ? (
        <div className="orders-table-container">
          <h2 className="section-title">Transactions</h2>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Id </th>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Deposit</th>
                  <th>Disbursement</th>
                  <th>User</th>
                  <th>Organization</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {orders.map((order: ITransaction) => (
                <tr key={order.id}>
                  
                  <td className='text-center'>
                  {renderPaymentMethodIcon(order)}
                  
                  </td>
                  <td>{renderShortId(order.id)}</td>
                  <td>{formatDate(order.createdAt)}</td>
                  <td>{renderTxType(order.type)}</td>
                  <td>{renderDepositCell(order)}</td>
                  <td>{renderDisbursementCell(order)}</td>
                  
                  <td>{renderCountryFlag(order.user._kycCountry, '10px !important')} <Link to={`/users/${order.user._id}`}>{order.user.email}</Link> </td>
                  <td>{renderOrganization(order.organization)}</td>
                  <td>{renderOrderStatus(order.status)}</td>
                  <td className="text-end">
                    <Link to={`/transactions/${order.id}`} className="btn btn-sm btn-outline-primary">
                      Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            </table>
          </div>
          {hasMore && (
            <div className="text-center mt-4">
              <button className="btn btn-outline-primary" onClick={loadMoreOrders} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Load More'}
              </button>
            </div>
          )}
          {!hasMore && (
            <p className="text-center mt-4 text-muted">No more orders to load</p>
          )}
        </div>
        ) : (
          renderEmptyState()
        )}
      </div>
    </div>
  );
});

export default Orders;
