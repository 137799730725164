import {
  faEye,
  faSearch,
  faStar,
  faStarHalfAlt,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Col,
  Form,
  Row,
  Spinner,
  Table
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDate } from "utils/dateHelpers";
import { IOrganization } from "../services/interfaces/Organization";
import { IUser } from "../services/interfaces/User";
import { useStores } from "../stores/index";
import "../styles/Users.scss";
import { renderCountryFlag } from "../utils/countryFlagHelper";
import { renderOrganization, renderShortId } from "utils/orderHelpers";
import { renderKycLevel } from '../utils/kycHelpers';

const Users: React.FC = observer(() => {
  const { appStore } = useStores();
  const [users, setUsers] = useState<IUser[]>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 50;

  // Filter states
  const [type, setType] = useState("ALL");
  const [status, setStatus] = useState("ALL");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");

  const { period, orgId } = appStore.filterState;

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    fetchUsers(true);
  }, [orgId, period, type, status]);

  useEffect(() => {
    if (organizations.length === 1 && !orgId) {
      appStore.setFilterState({ orgId: organizations[0]._id });
    }
  }, [organizations]);

  const fetchOrganizations = async () => {
    try {
      const fetchedOrganizations = await appStore.fetchOrganizations();
      setOrganizations(fetchedOrganizations);
    } catch (err) {
      console.error("Failed to fetch organizations:", err);
      setError("Failed to fetch organizations. Please try again later.");
    }
  };

  const calculateDateRange = (
    periodValue: string
  ): { dateFrom: string; dateTo: string } => {
    const now = new Date();
    now.setSeconds(59, 999); // Set to end of the current minute
    let dateFrom = new Date(now);
    dateFrom.setSeconds(0, 0); // Set to start of the current minute

    switch (periodValue) {
      case "last24hours":
        dateFrom.setHours(now.getHours() - 24);
        break;
      case "last72hours":
        dateFrom.setHours(now.getHours() - 72);
        break;
      case "last7days":
        dateFrom.setDate(now.getDate() - 7);
        break;
      case "last14days":
        dateFrom.setDate(now.getDate() - 14);
        break;
      case "last30days":
        dateFrom.setDate(now.getDate() - 30);
        break;
      case "lastYear":
        dateFrom.setFullYear(now.getFullYear() - 1);
        break;
      case "all":
        dateFrom = new Date(0); // Beginning of time
        break;
    }

    return {
      dateFrom: dateFrom.toISOString(),
      dateTo: now.toISOString(),
    };
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    appStore.setFilterState({ period: event.target.value });
  };

  const handleOrgIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    appStore.setFilterState({ orgId: event.target.value });
  };

  const fetchUsers = async (resetResults: boolean = false) => {
    setIsLoading(true);
    setError(null);
    try {
      const { dateFrom, dateTo } = calculateDateRange(period);
      const lastCreatedAtDate =
        users.length > 0 ? users[users.length - 1].createdAt : null;

      const queryParams = new URLSearchParams({
        limit: itemsPerPage.toString(),
        offset: ((currentPage - 1) * itemsPerPage).toString(),
        organizationId: orgId,
        dateFrom,
        dateTo,
        type,
        status,
        email,
        userId,
      });

      if (!resetResults && lastCreatedAtDate) {
        queryParams.set("dateTo", lastCreatedAtDate);
      }

      const fetchedUsers = await appStore.fetchUsers(
        `?${queryParams.toString()}`
      );

      if (resetResults) {
        setUsers(fetchedUsers);
        setCurrentPage(1);
      } else {
        setUsers((prevUsers) => [...prevUsers, ...fetchedUsers]);
      }

      const moreAvailable = fetchedUsers.length > 1;
      setHasMore(moreAvailable);
    } catch (err) {
      setError("Failed to fetch users. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    fetchUsers(true);
  };

  const loadMoreUsers = () => {
    if (!isLoading && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
      fetchUsers();
    }
  };

  

  if (isLoading && users.length === 0) {
    return (
     <div className="orders-container">
        <div className="content-wrapper">
          <div className="loading-container">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="loading-text">Loading transactions...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="users-container">
      <div className="content-wrapper">
        <div className="filter-container">
          <div className="">
            <div className="row g-3">
              <Form onSubmit={handleSearch}>
                <Row className="">
                  <Col md={2} sm={6}>
                    <Form.Group controlId="organization">
                      <Form.Label>Organization</Form.Label>
                      <Form.Select value={orgId} onChange={handleOrgIdChange}>
                        <option value="">All</option>
                        {organizations.map((org) => (
                          <option key={org._id} value={org._id}>
                            {org.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={2} sm={6}>
                    <Form.Group controlId="period">
                      <Form.Label>Period</Form.Label>
                      <Form.Select value={period} onChange={handlePeriodChange}>
                        <option value="last24hours">Last 24 hours</option>
                        <option value="last72hours">Last 72 hours</option>
                        <option value="last7days">Last 7 days</option>
                        <option value="last14days">Last 14 days</option>
                        <option value="last30days">Last 30 days</option>
                        <option value="lastYear">Last year</option>
                        <option value="all">All time</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={2} sm={6}>
                    <Form.Group controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="ALL">All</option>
                        <option value="L2">Verified: Full KYC</option>
                        <option value="L1">Verified: Quick KYC</option>
                        <option value="L0">Not verified</option>
                        <option value="DISABLED">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search by email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="userId">
                      <Form.Label>User ID</Form.Label>
                      <div className="input-group">
                        <Form.Control
                          type="text"
                          placeholder="Search by user ID"
                          value={userId}
                          onChange={(e) => setUserId(e.target.value)}
                        />
                        <button className="btn btn-primary" type="submit">
                          <FontAwesomeIcon icon={faSearch} className="me-2" />
                        </button>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>

        {error && <div className="alert alert-danger mt-3">{error}</div>}
        <div className="orders-table-container">
          <h2 className="section-title">Users</h2>
          <Table responsive hover>
            <thead>
              <tr>
                <th></th>
                <th>Id</th>
                <th>Date</th>
                <th>Email</th>
                <th>Organization</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user._id}>
                  <td className='text-center'>{renderCountryFlag(user._kycCountry)}</td>
                  <td>{renderShortId(user._id)}</td>
                  <td>{formatDate(user.createdAt)}</td>
                  <td>{user.email}</td>
                  <td>{renderOrganization(user.organization)}</td>
                  <td>{renderKycLevel(user)}</td>
                  <td className="text-end">
                    <Link
                      to={`/users/${user._id}`}
                      className="btn btn-sm btn-outline-primary"
                    >
                      Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {hasMore && (
            <div className="text-center mt-4">
              <button
                className="btn btn-outline-primary"
                onClick={loadMoreUsers}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Load More"}
              </button>
            </div>
          )}
          {!hasMore && (
            <p className="text-center mt-4 text-muted">No more users to load</p>
          )}

          {isLoading && (
            <div className="text-center mt-4">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Users;
