export const formatDate = (dateString: string, showYear = false) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: showYear ? 'numeric' : undefined,
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};