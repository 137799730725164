import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../stores/index';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import '../styles/SharedStyles.css';
import '../styles/Login.css';
import { getEnvironmentLabel } from '../utils/environmentUtils';

const Signin: React.FC = observer(() => {
  const { appStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [totp, setTotp] = useState('');
  const [showTotp, setShowTotp] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [endpoint, setEndpoint] = useState(appStore.currentEndpoint);

  const isDevEnvironment = process.env.REACT_APP_SHOW_ALL_ENDPOINTS === 'true';

  const endpoints = [
    { value: 'https://api.kado.money', label: 'Production' },
    { value: 'https://test-api.kado.money', label: 'Staging' },
    ...(isDevEnvironment ? [
      { value: 'https://dev-api.kado.money', label: 'Development' },
      { value: 'http://localhost:8090', label: 'Local' }
    ] : [])
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setShowTotp(searchParams.get('totp') === 'true');
  }, [location.search]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      if(!endpoint){
        appStore.setEndpoint(endpoints[0].value)
      }
      const res = await appStore.signin(email, password, totp);
      if (res.success) {
        navigate('/transactions');
      } else {
        setError(res.message || 'Login failed. Please try again.');
      }
    } catch (err: any) {
      setError(err.message || 'An unexpected error occurred during login');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEndpointChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newEndpoint = e.target.value;
    setEndpoint(newEndpoint);
    appStore.setEndpoint(newEndpoint);
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="login-form">
          <div className="card">
            <div className="card-body">
              <img src="kado-logo.svg" alt="Kado Logo" className="login-logo" />
              {error && <div className="alert alert-danger">{error}</div>}
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="endpoint" className="form-label">Environment</label>
                  <select
                    id="endpoint"
                    className="form-select"
                    value={endpoint}
                    onChange={handleEndpointChange}
                  >
                    {endpoints.map((ep) => (
                      <option key={ep.value} value={ep.value}>
                        {getEnvironmentLabel(ep.value)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="username"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    autoComplete="current-password"
                  />
                </div>
                {showTotp && (
                  <div className="mb-3">
                    <label htmlFor="totp" className="form-label">TOTP Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="totp"
                      value={totp}
                      onChange={(e) => setTotp(e.target.value)}
                      required
                    />
                  </div>
                )}
                <button 
                  type="submit" 
                  className="btn btn-primary w-100" 
                  disabled={isLoading}
                >
                  {isLoading ? 'Signing in...' : 'Sign in'}
                </button>
              </form>
              {isDevEnvironment && (
                <div className="mt-3 text-center">
                  <p>Don't have an account? <Link to="/signup">Sign up instead</Link></p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Signin;
