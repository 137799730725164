import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useStores } from '../stores';
import { CopyText } from './common/CopyText';

const OrganizationSetup: React.FC = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { appStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    name: '',
    friendlyName: '',
    accountName: '',
    businessEmail: '',
    webhookUrl: '',
    webhookSecret: '',
  });

  const organization = appStore.organizations.find(o => o._id === id);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const data = await appStore.fetchOrganizationById(id || '');
        setFormData({
          name: data.organization.name || '',
          friendlyName: data.organization.friendlyName || '',
          accountName: data.organization.accountName || '',
          businessEmail: data.organization.businessEmail || '',
          webhookUrl: data.organization.webhookUrl || '',
          webhookSecret: data.organization.webhookSecret || '',
        });
      } catch (error) {
        setError('Failed to load organization details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganization();
  }, [id, appStore]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);
    setSuccessMessage(null);

    try {
      await appStore.updateOrganization(id!, formData);
      setSuccessMessage('Organization updated successfully');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error) {
      setError('Failed to update organization');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="users-container">
      <div className="content-wrapper">
         
      <Row>
              <Col>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="section-title mb-0">{formData.name}</h2>
              </div>
              </Col>
             </Row>

        <Row>
          <Col md={12} lg={9}>
            
              

            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            <Form onSubmit={handleSubmit}>
                
              <Card className="mb-4 help-card">
              <Card.Title>Organization details</Card.Title>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Organization Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Text className="text-muted">
                          The main name of your organization
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3">
                    <Form.Label>Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.friendlyName}
                      disabled
                    />
                    <Form.Text className="text-muted">
                      This is your unique identifier and cannot be changed
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Business Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="businessEmail"
                      value={formData.businessEmail}
                      onChange={handleInputChange}
                    />
                    <Form.Text className="text-muted">
                      Used for business verification and important notifications
                    </Form.Text>
                  </Form.Group>
                </Card.Body>
              </Card>

              <Card className="mb-4 help-card">
                <Card.Title>Kado Widget</Card.Title>
                <Card.Body>
                  <Form.Group className="mb-4">
                    <Form.Label>Widget API Key</Form.Label>
                    <div>
                      <CopyText text={organization?.widgetId  || ''} />
                    </div>
                    <Form.Text className="text-muted">
                      Use this ID to integrate the Kado Widget
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Webhook URL</Form.Label>
                    <Form.Control
                      type="url"
                      name="webhookUrl"
                      value={formData.webhookUrl}
                      onChange={handleInputChange}
                      placeholder="https://"
                    />
                    <Form.Text className="text-muted">
                      URL where we'll send webhook notifications
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Webhook Secret</Form.Label>
                    <Form.Control
                      type="text"
                      name="webhookSecret"
                      value={formData.webhookSecret}
                      onChange={handleInputChange}
                    />
                    <Form.Text className="text-muted">
                      Used to verify webhook signatures
                    </Form.Text>
                  </Form.Group>
                </Card.Body>
              </Card>

              <Card className="mb-4 help-card">
                <Card.Title>Kado API</Card.Title>
                <Card.Body>
                  <Form.Group className="mb-4">
                    <Form.Label>API Public Key</Form.Label>
                    <div>
                      <CopyText text={organization?.apiPubKey || ''} />
                    </div>
                    <Form.Text className="text-muted">
                      Use this ID to integrate the Kado Widget
                    </Form.Text>
                  </Form.Group>

                </Card.Body>
              </Card>

              <Card className="mb-4 help-card">
                <Card.Title>Kado Pay</Card.Title>
                <Card.Body>
                  {!formData.businessEmail ? (
                    <div className="text-center py-3">
                      <p className="text-muted mb-3">Kado Pay is not configured for this organization</p>
                      <p className="small text-muted mb-4">
                        Enable Kado Pay to accept payments from your customers. This requires business verification (KYB).
                      </p>
                      <Button 
                        variant="outline-primary"
                        onClick={() => navigate(`/organizations/${id}/setup-pay`)}
                      >
                        Setup Kado Pay
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Form.Group className="mb-4">
                        <Form.Label>Business Email</Form.Label>
                        <div>
                          <Form.Control
                            type="email"
                            name="businessEmail"
                            value={formData.businessEmail}
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                        <Form.Text className="text-muted">
                          Email used for business verification
                        </Form.Text>
                      </Form.Group>

                      <div className="alert alert-success">
                        <div className="d-flex align-items-start">
                          <div className="me-3">
                            <i className="fas fa-check-circle fa-lg"></i>
                          </div>
                          <div>
                            <h6 className="alert-heading">Kado Pay is Enabled</h6>
                            <p className="mb-0 small">Your organization has completed business verification and can accept payments.</p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <a 
                          href="https://docs.kado.money/pay" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="btn btn-link px-0"
                        >
                          View Pay Documentation
                        </a>
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>

              <div className="d-flex justify-content-end">
                <Button 
                  variant="secondary" 
                  onClick={() => navigate('/organizations')}
                  className="me-2"
                >
                  Cancel
                </Button>
                <Button 
                  variant="primary" 
                  type="submit"
                  disabled={isSaving}
                >
                  <FontAwesomeIcon icon={faSave} className="me-2" />
                  {isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </Form>
          </Col>

          <Col sm={12} md={3}>
            <Card className="mb-4 help-card">
              <Card.Body>
                <Card.Title>Need Help?</Card.Title>
                <p>Check our documentation for detailed information about:</p>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="https://docs.kado.money/widget" target="_blank" rel="noopener noreferrer">
                      Widget Integration
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="https://docs.kado.money/webhooks" target="_blank" rel="noopener noreferrer">
                      Webhook Setup
                    </a>
                  </li>
                  <li>
                    <a href="https://docs.kado.money/api" target="_blank" rel="noopener noreferrer">
                      API Documentation
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default OrganizationSetup; 