import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useStores } from "../stores/index";
import {
  Spinner,
  ListGroup,
  Badge,
  Button,
  Alert,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import {
  FaCheck,
  FaClock,
  FaTimes,
  FaArrowLeft,
  FaUser,
  FaHistory,
  FaExclamationTriangle,
  FaArrowUp,
  FaArrowDown,
  FaMoneyBillWave,
  FaExternalLinkAlt,
  FaInfoCircle,
  FaDownload,
  FaUpload,
  FaRegClock,
} from "react-icons/fa";
import {

  formatCurrency,
  formatPaymentMethod,
  formatWalletAddress,
  getCountryNameFromCode,
} from "../utils/formatters";
import {
  getCryptoImageUrl,
  getPaymentMethodIconUrl,
} from "../utils/orderUtils";
import "../styles/OrderDetails.css";
import KadoIcon from "./KadoIcon";
import { formatDate } from "utils/dateHelpers";
import { renderDepositCell, renderDisbursementCell, renderOrganization } from "utils/orderHelpers";
import { renderCountryFlag } from "utils/countryFlagHelper";
import { renderOrderSummary } from '../utils/orderHelpers';
import { renderPaymentMethodIcon } from "utils/paymentMethodHelpers";
import { getEventInfo } from '../utils/userUpdateHelpers';
import TimelineHistory from "./TimelineHistory";


const OrderDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { appStore } = useStores();
  const [order, setOrder] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [i18nData, setI18nData] = useState<any>(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setIsLoading(true);
      try {
        if (id) {
          const response = await appStore.fetchOrderDetails(id);
          if (response) {
            setOrder(response);
          } else {
            setError("Invalid transaction data received");
          }
        } else {
          setError("Order ID is missing");
        }
      } catch (err) {
        setError(
          "Failed to fetch transaction details. Please try again later."
        );
      } finally {
        setIsLoading(false);
      }
    };

    const fetchI18nData = async () => {
      try {
        const response = await fetch(
          "https://kado-asset-haus.s3.amazonaws.com/i18n/en/US.json"
        );
        const data = await response.json();
        setI18nData(data);
      } catch (err) {
        console.error("Error fetching i18n data:");
      }
    };

    fetchOrderDetails();
    fetchI18nData();
  }, [id, appStore]);

  const getStatusIcon = (status: string) => {
    switch (status?.toLowerCase()) {
      case "completed":
      case "success":
        return <FaCheck className="text-success" />;
      case "pending":
        return <FaClock className="text-warning" />;
      case "failed":
        return <FaTimes className="text-danger" />;
      default:
        return <FaRegClock className="" />;
    }
  };

  const getStatusBadge = (status: string | undefined) => {
    
    let variant = "warning";
    switch (status?.toLowerCase()) {
      case "completed":
      case "success":
      case "settled":
      case "off ramp settled":
        variant = "success";
        break;
      case "failed":
        variant = "danger";
        break;
    }
    return (
      <Badge
        bg={variant}
        className="text-uppercase"
        style={{ display: "inline-block", marginLeft: "5px" }}
      >
        {status}
      </Badge>
    );
  };

  const getErrorMessage = (errorCode: string) => {
    if (!i18nData || !errorCode) return errorCode;

    const keys = errorCode.split(".");
    let message = i18nData.messages;

    for (const key of keys) {
      if (message && message[key]) {
        message = message[key];
      } else {
        return errorCode; // Return original error code if path is not found
      }
    }

    return typeof message === "string" ? message : errorCode;
  };

  const getShortErrorCode = (errorCode: string) => {
    if (!errorCode) return "";
    const parts = errorCode.split(".");
    return parts.length > 2 ? parts.slice(-2).join(".") : errorCode;
  };

  const renderTxType = (txType: string | undefined) => {
    if (!txType) return "Unknown";

    switch (txType.toLowerCase()) {
      case "buy":
        return (
          <span className="tx-type buy">
            <FaArrowUp className="mr-1" /> Buy
          </span>
        );
      case "sell":
        return (
          <span className="tx-type sell">
            <FaArrowDown className="mr-1" /> Sell
          </span>
        );
      default:
        return txType;
    }
  };

  const safelyFormatCurrency = (
    amount: number | undefined,
    fallback: string = "N/A"
  ) => {
    return amount !== undefined ? formatCurrency(amount) : fallback;
  };

  if (isLoading) {
    return (
      <div className="order-details-container">
        <div className="content-wrapper">
          <div className="loading-container">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="loading-text">Loading transaction details...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger mt-3">{error}</div>;
  }

  if (!order) {
    return <div>No transaction found</div>;
  }

  return (
    <div className="order-details-container">
      <div className="content-wrapper">
        <h1 className="page-title">
          {renderOrderSummary(order)}
        </h1>
        <Row>
          <Col md={4}>
            <div className="order-section mb-4">
              <h2>Transaction Details</h2>
              <hr />
              <Table className="mtable">
                <tbody>
                
                  <tr>
                    <td><strong>Type:</strong></td>
                    <td>{renderTxType(order.type)}</td>
                  </tr>
                  <tr>
                    <td><strong>Asset:</strong></td>
                    <td>{order.asset}</td>
                  </tr>
                  <tr>
                    <td><strong>User:</strong></td>
                    <td> <Link to={`/users/${order.user._id}`}>{order.user.email}</Link></td>
                  </tr>
                  <tr>
                    <td><strong>Country:</strong></td>
                    <td>{renderCountryFlag(order.user._kycCountry)} {getCountryNameFromCode(order.user._kycCountry)}</td>
                  </tr>
                  <tr>
                    <td><strong>Id:</strong></td>
                    <td>{order.id}</td>
                  </tr>
                  <tr>
                    <td><strong>Date:</strong></td>
                    <td>{formatDate(order.createdAt, true)}</td>
                  </tr>
                  <tr>
                    <td><strong>Organization:</strong></td>
                    <td>{renderOrganization(order.organization)}</td>
                  </tr>
                  <tr>
                    <td><strong>Status:</strong></td>
                    <td>{getStatusBadge(order.status)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
           
          </Col>
          <Col md={8}>
          {order.errorCode && (
              <div className="error-section mb-4">
                <h2>Error</h2>
                <hr />
                <div>
                  <p>
                    <strong>Error Code:</strong> <br />
                    {getShortErrorCode(order.errorCode)}
                  </p>
                  <p>
                    <strong>Message displayed to the user:</strong> <br />
                    {getErrorMessage(order.errorCode)}
                  </p>
                </div>
              </div>
            )}
            
            <div className="order-section mb-4">
              <h2>Deposit</h2>
              <hr />
              
              <p>
                <strong>Amount:</strong>
                {renderDepositCell(order)}
              </p>

              {order.type === "BUY" ? (
                <>
                  <p>
                    <strong>Method:</strong>{" "}
                    {renderPaymentMethodIcon(order)} 
                     {formatPaymentMethod(order.depositMethod)}
                  </p>
                </>
              ) : order.type === "SELL" || order.type === "PAY" ? (
                <>
                  <p>
                    <strong>Wallet Address:</strong>{" "}
                    {renderPaymentMethodIcon(order)}
                    {order.walletAddress}
                  </p>
                </>
              ) : null}
              {order.type !== "BUY" && order.txHash && (
                <p>
                  <strong>Transaction Hash:</strong>{" "}
                  <a
                    href={`${order.blockExplorerURI}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.txHash} <FaExternalLinkAlt size="0.8em" />
                  </a>
                </p>
              )}
              {order.type !== "BUY" && order.settledAt && (
                <p>
                  <strong>Transaction Settled At:</strong>{" "}
                  {formatDate(order.settledAt, true)}
                </p>
              )}
              {order.type !== "BUY" && order.memo && (
                <p>
                  <strong>Memo:</strong> {order.memo}
                </p>
              )}
              <p>
                <strong>Status:</strong> {getStatusBadge(order.depositStatus)}
              </p>
            </div>

            <div className="order-section mb-4">
              <h2>Disbursement</h2>
              <hr />
              <p>
                <strong>Amount:</strong>
                {renderDisbursementCell(order)}
              </p>
              {order.type === "BUY" ? (
                <>
                  <p>
                    <strong>Destination:</strong>{" "}
                    {order.disbursementMethod || "N/A"}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Method:</strong>{" "}
                    {renderPaymentMethodIcon(order)}
                    {formatPaymentMethod(order.disbursementMethod)}
                  </p>
                </>
              )}

              {order.type === "BUY" && order.txHash && (
                <p>
                  <strong>Transaction Hash:</strong>{" "}
                  <a
                    href={`${order.blockExplorerURI}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.txHash} <FaExternalLinkAlt size="0.8em" />
                  </a>
                </p>
              )}
              {order.type === "BUY" && order.settledAt && (
                <p>
                  <strong>Transaction Settled At:</strong>{" "}
                  {formatDate(order.settledAt, true)}
                </p>
              )}
              {order.type === "BUY" && order.memo && (
                <p>
                  <strong>Memo:</strong> {order.memo}
                </p>
              )}
              <p>
                <strong>Status:</strong>{" "}
                {getStatusBadge(order.disbursementStatus)}
              </p>
            </div>
                      
                        {order && order.updates && order.updates.length > 0 ? (
                          <TimelineHistory updates={order.updates} />
                        ) : (
                          <Alert variant="info">Timeline is not available for this order.</Alert>
                        )}
                      
              

            
          </Col>

 
        </Row>
      </div>
    </div>
  );
};

export default OrderDetails;
