import React from 'react';
import { formatDate } from '../utils/dateHelpers';
import { getEventInfo } from '../utils/userUpdateHelpers';

type TimelineUpdate = {
  event?: string;  
  newStatus?: string; 
  message: string;
  details: Record<string, any>;
  timestamp: string;
  createdAt?: string;
  updatedAt?: string;
};

interface TimelineUpdateHistoryProps {
  updates: TimelineUpdate[];
}

const TimelineHistory: React.FC<TimelineUpdateHistoryProps> = ({ updates }) => {


  
  return (
    <div className="timeline-section mb-4">
      <h2>Timeline</h2>
      <hr />
      <div className="timeline">
        {updates && updates.length > 0 ? (
          updates.map((update, index) => {
            //TEMPORARY HACK
             if(update.message?.includes("Successfully transferred funds from instant settlement")){
              update.newStatus = "FUNDS TRANSFERRED TO USER ACCOUNT";
            }
            if(update.newStatus?.includes("FIREBLOCKS DISBURSEMENT")){
              update.newStatus = "DISBURSEMENT INITIATED";
            }
            if(update.newStatus?.includes("FORTRESS DISBURSEMENT")){
              update.newStatus = "DISBURSEMENT INITIATED";
            }
            if(update.event?.includes("USER_COMPLETED_QUEST")){
              update.event = `USER EARNED ${update.details.pointsEarned} Kado Points`;
            }
            //END TEMPORARY HACK
            const { icon, message } = getEventInfo(update.event || update.newStatus || "");
            return (
              <div key={index} className="timeline-item">
                <div className="timeline-icon">
                  {icon}
                </div>
                <div className="timeline-content">
                  <strong className="timeline-details">{message}</strong>
                  <p className="timeline-date">{formatDate(update.timestamp || update.createdAt  || update.updatedAt || "", true)}</p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="timeline-item">
            <div className="timeline-content">
              <p>No timeline events available</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimelineHistory;
