import * as React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface KadoIconProps {
  folder: 'chains' | 'tokens' | 'bridges' | 'payments' | 'country_flags'
  name:string 
  tooltipText?:string
  height?:string
  inline?:boolean
}

function KadoIcon({ folder, name, tooltipText, height='20px', inline=false }: KadoIconProps) {
  const imgUrl = `https://kado-asset-haus.s3.amazonaws.com/images/${folder}/light/${name?.toLocaleLowerCase()}.png`.replace(' ', '_')
  
  return name ? (
    <>
      {tooltipText ? (
        <OverlayTrigger placement="top" overlay={<Tooltip>{tooltipText}</Tooltip>}>
          <img
            src={imgUrl}
            height={height}
            className={`kado-icon ${inline ? 'd-inline' : ''}`}
          />
        </OverlayTrigger>
      ) : (
        <img
          src={imgUrl}
          height={height}
          className={`kado-icon ${inline ? 'd-inline' : ''}`}
        />
      )}
    </>
  ) : null
}

export default KadoIcon;
