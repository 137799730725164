import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useStores } from '../stores';

const OrganizationDetails = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { appStore } = useStores();
  const [organization, setOrganization] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const org = await appStore.fetchOrganizationById(id || '');
        setOrganization(org);
      } catch (error) {
        console.error('Failed to fetch organization:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganization();
  }, [id, appStore]);

  const renderStatus = (isEnabled: boolean) => {
    return isEnabled ? (
      <FontAwesomeIcon icon={faPowerOff} className="me-1 text-success" />
    ) : (
      <FontAwesomeIcon icon={faPowerOff} className="me-1 text-danger" />
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!organization) {
    return <div>Organization not found</div>;
  }

  return (
    <div className="users-container">
      <div className="content-wrapper">
        <Row>
          <Col md={12} lg={9}>
            {/* Organization Overview */}
            <Card className="mb-4">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Organization Details</h5>
                <Button 
                  variant="outline-primary" 
                  size="sm"
                  onClick={() => navigate(`/organizations/${id}/edit`)}
                >
                  Edit
                </Button>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <p><strong>Display Name:</strong> {organization.name}</p>
                    <p><strong>Account Name:</strong> <code>{organization.accountName}</code></p>
                    <p><strong>Business Email:</strong> {organization.businessEmail || 'Not set'}</p>
                  </Col>
                  <Col md={6}>
                    <p><strong>Created:</strong> {new Date(organization.createdAt).toLocaleDateString()}</p>
                    <p><strong>Type:</strong> {organization.type || 'SIMPLE'}</p>
                    <p><strong>Status:</strong> {organization.status || 'ACTIVE'}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Integration Status */}
            <Card className="mb-4">
              <Card.Header>
                <h5 className="mb-0">Integration Status</h5>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Service</th>
                      <th>Status</th>
                      <th>Details</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Kado Widget</td>
                      <td>{renderStatus(true)}</td>
                      <td>Always enabled</td>
                      <td>
                        <Button 
                          variant="link" 
                          size="sm"
                          href="https://docs.kado.money/widget" 
                          target="_blank"
                        >
                          View Docs <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Kado API</td>
                      <td>{renderStatus(true)}</td>
                      <td>Always enabled</td>
                      <td>
                        <Button 
                          variant="link" 
                          size="sm"
                          href="https://docs.kado.money/api" 
                          target="_blank"
                        >
                          View Docs <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Kado Pay</td>
                      <td>{renderStatus(!!organization.businessEmail)}</td>
                      <td>
                        {organization.businessEmail 
                          ? 'Enabled with business verification' 
                          : 'Requires business verification'}
                      </td>
                      <td>
                        {organization.businessEmail ? (
                          <Button 
                            variant="link" 
                            size="sm"
                            href="https://docs.kado.money/pay" 
                            target="_blank"
                          >
                            View Docs <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
                          </Button>
                        ) : (
                          <Button 
                            variant="link" 
                            size="sm"
                            onClick={() => navigate(`/organizations/${id}/setup-pay`)}
                          >
                            Setup Pay
                          </Button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={3}>
            {/* Help Section */}
            <Card className="mb-4 help-card">
              <Card.Body>
                <Card.Title>Resources</Card.Title>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="https://docs.kado.money" target="_blank" rel="noopener noreferrer">
                      Integration Documentation
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="https://docs.kado.money/widget" target="_blank" rel="noopener noreferrer">
                      Widget Documentation
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="https://docs.kado.money/api" target="_blank" rel="noopener noreferrer">
                      API Documentation
                    </a>
                  </li>
                  <li>
                    <a href="https://faq.kado.money" target="_blank" rel="noopener noreferrer">
                      FAQ
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Card>

            {/* Support Card */}
            <Card className="help-card">
              <Card.Body>
                <Card.Title>Need Help?</Card.Title>
                <p>Contact our support team for assistance with your integration.</p>
                <Button 
                  variant="outline-primary" 
                  href="mailto:support@kado.money"
                >
                  Contact Support
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default OrganizationDetails; 