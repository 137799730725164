import React from 'react';
import { Badge } from 'react-bootstrap';

interface BadgeComponentProps {
  status: string | undefined;
}

const BadgeComponent: React.FC<BadgeComponentProps> = ({ status }) => {
  let variant = 'secondary';
  
  switch (status?.toLowerCase()) {
    case 'pending':
      variant = 'warning';
      break;
    case 'paid':
    case 'completed':
    case 'settled':
    case 'success':
    case 'active':
      variant = 'success';
      break;
    case 'failed':
    case 'disabled':
      variant = 'danger';
      break;
  }

  return (
    <Badge bg={variant} className="text-uppercase" style={{ display: 'inline-block', marginLeft: '5px' }}>
      {status}
    </Badge>
  );
};

export default BadgeComponent;