import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../stores/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Card, Alert, Spinner } from 'react-bootstrap';
import '../styles/SharedStyles.css';
import '../styles/Login.css';

const ActivateAccount: React.FC = observer(() => {
  const { appStore } = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const usernameParam = params.get('u');
    const codeParam = params.get('c');
    const endPointParam = params.get('e');

    if (usernameParam && codeParam && endPointParam) {
      setUsername(usernameParam);
      setActivationCode(codeParam);
      appStore.setEndpoint(endPointParam);
      appStore.logout(false);
      setIsLoading(false);
    } else {
      setError('Invalid activation link. Please contact support.');
      setIsLoading(false);
    }
  }, [location, appStore]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long.');
      setIsLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await appStore.completeNewPasswordChallenge(username, activationCode, newPassword);
      if (response.success) {
        navigate('/dashboard');
      } else {
        setError(response.message || 'Failed to set new password.');
      }
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="login-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="login-form">
          <Card>
            <Card.Body>
              <img src="kado-logo.svg" alt="Kado Logo" className="login-logo" />
              <Card.Title>Activate your Kado account</Card.Title>
              <p className='pt-2 mb-1 text-sm'>Set your password to complete the account setup:</p>
              <ul className='pb-4 pl-0 text-sm'>
                <li>- Use at least 8 characters</li>
                <li>- Include uppercase and lowercase letters</li>
                <li>- Use numbers and special characters</li>
                <li>- Avoid using personal information</li>
              </ul>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  Activate Account
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
});

export default ActivateAccount;