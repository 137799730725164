import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card, Col, Row, Table, Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useStores } from '../stores/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faPowerOff, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CopyText } from './common/CopyText';

const Settings = observer(() => {
  const { appStore } = useStores();
  const navigate = useNavigate();
  const [endpoint, setEndpoint] = React.useState(appStore.currentEndpoint);

  React.useEffect(() => {
    appStore.fetchOrganizations();
  }, [appStore]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    appStore.setEndpoint(endpoint);
    window.location.reload();
  };

  const renderStatus = (isEnabled: boolean) => {
    return isEnabled ? (
      <FontAwesomeIcon icon={faPowerOff} className="me-1 text-success" />
    ) : (
      <><FontAwesomeIcon icon={faPowerOff} className="me-1 text-danger" /></>
    );
  };

  return (
    <div className="users-container">
      <div className="content-wrapper">
             
             <Row>
              <Col>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="section-title mb-0">Settings</h2>
              </div>
              </Col>
             </Row>
      
        <Row>
          <Col md={12} lg={9}>
            <div className="orders-table-container">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="section-title mb-0">Organizations</h2>
              </div>

              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Organization</th>
                    <th className="text-center">Kado Widget</th>
                    <th className="text-center">Kado API</th>
                    <th className="text-center">Kado Pay</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {appStore.organizations.map((organization, index) => (
                    <tr key={organization._id}>
                      <td>{organization.name} <small className='text-muted'>{organization.friendlyName}</small></td>
                      <td className="text-center">
                        <CopyText text={organization.widgetId} shorten />
                      </td>
                      <td className="text-center">
                        <CopyText text={organization.apiPubKey} shorten />
                      </td>
                      <td className="text-center">
                        {organization.businessEmail ? organization.businessEmail : "Not set"}
                      </td>
                      <td className="text-end">
                        <Link
                          to={`/organizations/${organization._id}/setup`}
                          className="btn btn-sm btn-outline-primary"
                        >
                          Setup
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button  
                variant="outline-primary"
                size="sm"
                onClick={() => navigate('/organizations/new')}
                className='mt-3'
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                New Organization
              </Button>
            </div>
          </Col>
          
          <Col sm={12} md={3}>
            <Card className="mb-4 help-card ">
              <Card.Body>
                <Card.Title>Secure Your Account</Card.Title>
                <p>Ensure your account is protected by updating your password regularly.</p>
                
                <Link to="/change-password" className="btn btn-outline-primary">
                  Change password
                </Link>
                
              </Card.Body>
            </Card>
            <Card className="mb-4 help-card">
              <Card.Body>
                <Card.Title>Help</Card.Title>
                <p>If you need assistance, please refer to the following resources:</p>
                <ul>
                  <li><a href="https://docs.kado.money" target="_blank" rel="noopener noreferrer">Integration Documentation</a></li>
                  <li><a href="https://faq.kado.money/en/" target="_blank" rel="noopener noreferrer">Kado FAQ</a></li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default Settings;