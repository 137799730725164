import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStores } from '../stores/index';
import '../styles/TopMenu.css';
import { getEnvironmentLabel, isNonProdEnvironment } from '../utils/environmentUtils';

const TopMenu: React.FC = () => {
  const location = useLocation();
  const { appStore } = useStores();

  if (!appStore.isAuthenticated) {
    return null;
  }

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    appStore.logout();
  };

  return (
    <nav className="top-menu">
      {isNonProdEnvironment(appStore.currentEndpoint) && (
        <div className="environment-label">
          {getEnvironmentLabel(appStore.currentEndpoint)}
        </div>
      )}
      <div className="menu-container">
        <Link className="menu-brand" to="/dashboard">
          <img src="/kado-logo.svg" alt="Dashboard Logo" className="menu-logo" />
        </Link>
        <ul className="menu-items">
          <li>
            <Link className={`menu-link ${location.pathname === '/dashboard' ? 'active' : ''}`} to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link className={`menu-link ${location.pathname === '/transactions' ? 'active' : ''}`} to="/transactions">Transactions</Link>
          </li>
          <li>
            <Link className={`menu-link ${location.pathname === '/users' ? 'active' : ''}`} to="/users">Users</Link>
          </li>
          <li>
            <Link className={`menu-link ${location.pathname === '/settings' ? 'active' : ''}`} to="/settings">Settings </Link>
          </li>
          <li>
            <a href="#" className="menu-link" onClick={handleLogout} title="Logout">
              <FontAwesomeIcon icon={faSignOutAlt} /> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TopMenu;