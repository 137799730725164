import React, { createContext, ReactNode, useContext } from 'react';
import Settings from '../services/interfaces/Settings';
import { ApiWrapper } from '../services/lib/ApiWrapper';
import { AppStore } from './AppStore';

interface StoresContextValue {
  appStore: AppStore;
}

const StoresContext = createContext<StoresContextValue | null>(null);

interface StoresProviderProps {
  children: ReactNode;
}

export const StoresProvider: React.FC<StoresProviderProps> = ({ children }) => {
  
  const settings: Settings = {
    endpoint: process.env.REACT_APP_DEFAULT_API_URL || ''
  };

  const apiWrapper = new ApiWrapper(settings);
  const appStore = new AppStore(apiWrapper);

  return React.createElement(StoresContext.Provider, { value: { appStore: appStore } }, children);
};

export const useStores = (): StoresContextValue => {
  const context = useContext(StoresContext);
  if (!context) {
    throw new Error('useStores must be used within a StoresProvider');
  }
  return context;
};