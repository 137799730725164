import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useStores } from '../stores';

const OrganizationPaySetup: React.FC = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { appStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    businessEmail: '',
    businessName: '',
    businessAddress: '',
    businessPhone: '',
    businessWebsite: '',
  });

  const organization = appStore.organizations.find(o => o._id === id);

  useEffect(() => {
    if (organization) {
      setFormData(prev => ({
        ...prev,
        businessEmail: organization.businessEmail || '',
      }));
      setIsLoading(false);
    }
  }, [organization]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);

    try {
      await appStore.updateOrganization(id!, {
        ...formData,
        type: 'PAY'
      });
      navigate(`/organizations/${id}/setup`);
    } catch (error) {
      setError('Failed to enable Kado Pay');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="users-container">
      <div className="content-wrapper">
      <Row>
              <Col>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="section-title mb-0">{organization?.name}</h2>
              </div>
              </Col>
             </Row>

        

        <Row>
          <Col md={12} lg={9}>
           

            {error && <Alert variant="danger">{error}</Alert>}

            <Card className="mb-4 help-card">
              <Card.Header>
                <h5 className="mb-0">Kado Pay - Business Verification</h5>
              </Card.Header>
              <Card.Body>
                <Alert variant="info" className="mb-4">
                  <h6 className="alert-heading">Important Information</h6>
                  <p className="mb-0">
                    To enable Kado Pay, we need to verify your business. Please provide accurate information as it will be used during the verification process.
                  </p>
                </Alert>

                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Business Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="businessName"
                          value={formData.businessName}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Business Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="businessEmail"
                          value={formData.businessEmail}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Text className="text-muted">
                          This email will receive KYB verification instructions
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3">
                    <Form.Label>Business Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="businessAddress"
                      value={formData.businessAddress}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Business Phone</Form.Label>
                        <Form.Control
                          type="tel"
                          name="businessPhone"
                          value={formData.businessPhone}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Business Website</Form.Label>
                        <Form.Control
                          type="url"
                          name="businessWebsite"
                          value={formData.businessWebsite}
                          onChange={handleInputChange}
                          placeholder="https://"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="mt-4">
                    <h6 className="mb-3">Next Steps:</h6>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                        Submit business information
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                        Receive verification email
                      </li>
                      <li className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                        Complete KYB process
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
                        Start accepting payments
                      </li>
                    </ul>
                  </div>

                  <div className="d-flex justify-content-end mt-4">
                    <Button 
                      variant="secondary" 
                      onClick={() => navigate(`/organizations/${id}/setup`)}
                      className="me-2"
                    >
                      Cancel
                    </Button>
                    <Button 
                      variant="primary" 
                      type="submit"
                      disabled={isSaving}
                    >
                      {isSaving ? 'Enabling...' : 'Enable Kado Pay'}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={3}>
            <Card className="help-card">
              <Card.Body>
                <Card.Title>About Kado Pay</Card.Title>
                <p>Kado Pay enables your organization to:</p>
                <ul>
                  <li>Accept crypto payments</li>
                  <li>Process transactions securely</li>
                  <li>Access detailed payment analytics</li>
                  <li>Manage customer payments</li>
                </ul>
                <hr />
                <p className="mb-0">
                  <a 
                    href="https://docs.kado.money/pay"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more about Kado Pay
                  </a>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default OrganizationPaySetup; 