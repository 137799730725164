import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/global.scss';
import App from './App';
import { StoresProvider } from './stores/index';

// Import Bootstrap CSS and JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Import Chart.js styles
import 'chart.js/auto';


ReactDOM.render(
  <React.StrictMode>
    <StoresProvider>
      <App />
    </StoresProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
