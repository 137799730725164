import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

// Initialize the countries library with English locale
countries.registerLocale(enLocale);

export const formatCurrency = (amount: number, currency: string = 'USD', locale: string = 'en-US'): string => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount);
};

export const formatPaymentMethod = (method: string | undefined): string => {
  if (!method) return 'N/A';
  return method
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const formatWalletAddress = (address: string | undefined): string => {
  if (!address) return '';
  if (address.length <= 10) return address;
  return `${address.slice(0, 5)}...${address.slice(-5)}`;
};

/**
 * Converts a 2-letter country code to its full name in English
 * @param countryCode - The 2-letter ISO 3166-1 alpha-2 country code
 * @returns The full country name in English, or the original code if not found
 */
export const getCountryNameFromCode = (countryCode: string): string => {
  if (!countryCode) return 'N/A';
  
  const countryName = countries.getName(countryCode.toUpperCase(), 'en');
  return countryName || countryCode;
};
