import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';
import { AppStore } from '../stores/AppStore';
import { Button, Table, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CopyText } from './common/CopyText';

interface Props {
  store: AppStore;
}

export const OrganizationsPage: React.FC<Props> = observer(({ store }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    store.fetchOrganizations();
  }, [store]);

  if (store.organizations.length === 0) {
    return (
      <div className="users-container">
        <div className="content-wrapper">
          <div className="text-center mt-5">
            <h2>No Organizations Found</h2>
            <p className="text-muted mb-4">Get started by creating your first organization</p>
            <Button 
              variant="primary"
              onClick={() => navigate('/organizations/new')}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Create Organization
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="users-container">
      <div className="content-wrapper">
        <div className="orders-table-container">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="section-title mb-0">Organizations</h2>
          </div>

          {store.organizations.map(org => (
            <Card key={org._id} className="mb-4">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{org.name}</h5>
                <Link
                  to={`/organizations/${org._id}`}
                  className="btn btn-sm btn-outline-primary"
                >
                  Details
                </Link>
              </Card.Header>
              <Card.Body>
                <div className="row g-4">
                  {/* Widget Section */}
                  <div className="col-md-4">
                    <div className="border rounded p-3 h-100">
                      <h6 className="mb-3">Kado Widget</h6>
                      <div className="mb-2">
                        <small className="text-muted d-block mb-1">Widget ID</small>
                        <CopyText text={org.widgetId} shorten />
                      </div>
                      <div className="mt-3">
                        <a 
                          href="https://docs.kado.money/widget" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="btn btn-sm btn-link px-0"
                        >
                          View Documentation
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* API Section */}
                  <div className="col-md-4">
                    <div className="border rounded p-3 h-100">
                      <h6 className="mb-3">Kado API</h6>
                      <div className="mb-2">
                        <small className="text-muted d-block mb-1">Public Key</small>
                        <CopyText text={org.apiPubKey} shorten />
                      </div>
                      <div className="mt-3">
                        <a 
                          href="https://docs.kado.money/api" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="btn btn-sm btn-link px-0"
                        >
                          View Documentation
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* Pay Section */}
                  <div className="col-md-4">
                    <div className="border rounded p-3 h-100">
                      <h6 className="mb-3">Kado Pay</h6>
                      <div className="mb-2">
                        <small className="text-muted d-block mb-1">Status</small>
                        {org.businessEmail ? (
                          <span className="text-success">Enabled</span>
                        ) : (
                          <span className="text-muted">Not configured</span>
                        )}
                      </div>
                      <div className="mt-3">
                        {org.businessEmail ? (
                          <a 
                            href="https://docs.kado.money/pay" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="btn btn-sm btn-link px-0"
                          >
                            View Documentation
                          </a>
                        ) : (
                          <Button 
                            variant="link" 
                            size="sm" 
                            className="px-0"
                            onClick={() => navigate(`/organizations/${org._id}/setup-pay`)}
                          >
                            Setup Pay
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}

          <Button  
            variant="outline-primary"
            size="sm"
            onClick={() => navigate('/organizations/new')}
            className='mt-3'
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            New Organization
          </Button>
        </div>
      </div>
    </div>
  );
}); 