import React from 'react';
import { FaCheck, FaClock, FaUser, FaCreditCard, FaExclamationTriangle, FaRegClock, FaMoneyBillWave, FaExchangeAlt, FaCross, FaStar, FaStarHalfAlt, FaRoute, FaTrophy } from 'react-icons/fa';

interface EventInfo {
  icon: JSX.Element;
  message: string;
}

export const getEventInfo = (event: string): EventInfo => {




    let message = event.replace(/_/g, ' ')
    message = message.charAt(0).toUpperCase() + message.slice(1).toLowerCase()
    let icon = <FaRegClock className="text-secondary" />
    let variant = "secondary"

    if (event.toLowerCase().includes("succeeded") ||
    event.toLowerCase().includes("settled") ||
    event.toLowerCase().includes("created") ||
    event.toLowerCase().includes("added") ||
    event.toLowerCase().includes("flushed") ||
    event.toLowerCase().includes("disbursement") ||
    event.toLowerCase().includes("completed") ||
    event.toLowerCase().includes("initiated") ||
    event.toLowerCase().includes("earned")) {
        variant = "success"
    }else if (event.toLowerCase().includes("failed")) {
        variant = "danger"
    }
    else{
        variant = "secondary"
    }

    
    if (event.toLowerCase().includes("kyc")) {
        icon = <FaStar className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("points")) {
        icon = <FaTrophy className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("payment_method")) {
      icon = <FaMoneyBillWave className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("payment")) {
        icon = <FaMoneyBillWave className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("deposit")) {
        icon = <FaMoneyBillWave className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("user")) {
        icon = <FaUser className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("router")) {
        icon = <FaRoute className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("disbursement")) {
        icon = <FaRoute className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("points")) {
        icon = <FaTrophy className={`text-${variant}`} />
    }else if (event.toLowerCase().includes("settled")) {
            icon = <FaCheck className={`text-${variant}`} />
    }else{
        icon = <FaClock  className={`text-${variant}`} />
    }
    

    return {
      icon,
      message
    }

};
