import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  text: string | undefined;
  shorten?: boolean;
  className?: string;
}

export const CopyText: React.FC<Props> = ({ text = '', shorten = false, className = '' }) => {
  const [copied, setCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  if (!text) {
    return <span className={className}>-</span>;
  }

  const displayText = shorten && text.length > 10
    ? `${text.slice(0, 5)}...${text.slice(-5)}`
    : text;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <span
      className={`d-inline-flex align-items-center ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ position: 'relative' }}
    >
      <code className="me-2 text-primary">{displayText}</code>
      <span 
        style={{ 
          width: '20px',
          opacity: isHovered ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
          cursor: 'pointer',
          fontSize: '0.875rem',
          color: copied ? '#198754' : '#6c757d'
        }}
      >
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${text}`}>
              {copied ? 'Copied!' : 'Copy to clipboard'}
            </Tooltip>
          }
        >
          <FontAwesomeIcon 
            icon={copied ? faCheck : faCopy} 
            onClick={handleCopy}
          />
        </OverlayTrigger>
      </span>
    </span>
  );
}; 