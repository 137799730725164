import React from 'react';
import { FaArrowUp, FaArrowDown, FaArrowRight, FaCheck, FaTimes, FaClock, FaQuestion } from 'react-icons/fa';
import BadgeComponent from '../components/BadgeComponent';

export const renderTxType = (txType: string | undefined) => {
  if (!txType) {
    return 'Unknown';
  }
  switch (txType.toLowerCase()) {
    case 'buy':
      return (
        <span className="tx-type buy">
          <FaArrowUp className="mr-1" /> Buy
        </span>
      );
    case 'sell':
      return (
        <span className="tx-type sell">
          <FaArrowDown className="mr-1" /> Sell
        </span>
      );
    case 'pay':
      return (
        <span className="tx-type pay">
          <FaArrowRight className="mr-1" /> Pay
        </span>
      );
    default:
      return txType;
  }
};

export const renderOrderStatus = (status: string | undefined): JSX.Element => {
  return <BadgeComponent status={status} />;
};

export const getStatusIcon = (status: string) => {
  switch (status.toLowerCase()) {
    case 'settled':
      return <FaCheck className="text-success" />;
    case 'failed':
      return <FaTimes className="text-danger" />;
    case 'pending':
      return <FaClock className="text-warning" />;
    default:
      return <FaQuestion className="text-secondary" />;
  }
};