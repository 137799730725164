import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCreditCard, 
  faUniversity, 
  faMoneyBillWave, 
  faQuestionCircle 
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getPaymentMethodIconUrl } from './orderUtils';
import { formatPaymentMethod } from './formatters';
import { ITransaction } from 'services/interfaces/Transaction';

export const renderPaymentMethodIcon = (transaction: ITransaction) => {
  let icon;
  let tooltipText;

  
  if (!transaction.depositMethod) return null;

  const url = getPaymentMethodIconUrl(transaction.depositMethod)
  const method = formatPaymentMethod(transaction.depositMethod)
  

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${transaction.paymentMethod}`}>{method}</Tooltip>}
    >
      <span>
      <img 
            src={url}
            alt={method}
            className="payment-icon"
          />
      </span>
    </OverlayTrigger>
  );
};
