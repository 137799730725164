import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { AppStore } from '../stores/AppStore';
import { Button, Card, Form, ProgressBar, Alert, Row, Col } from 'react-bootstrap';

interface Props {
  store: AppStore;
}

type FormStep = 
  | 'name-check' 
  | 'integration-choice'
  | 'kado-pay-setup'
  | 'basic-setup'
  | 'review';

export const OrganizationForm: React.FC<Props> = observer(({ store }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = id !== 'new';
  const [currentStep, setCurrentStep] = useState<FormStep>('name-check');
  const [isCheckingName, setIsCheckingName] = useState(false);
  const [nameError, setNameError] = useState<string | null>(null);
  const [accountNameError, setAccountNameError] = useState<string | null>(null);
  
  const [formData, setFormData] = useState({
    displayName: '',
    accountName: '',
    personalEmail: store.user?.email || '',
    businessName: '',
    businessEmail: '',
    setupKadoPay: false,
    kadoPayDetails: {
      enableGooglePay: false,
      address: '',
      phone: '',
      website: ''
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleBusinessDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      kadoPayDetails: {
        ...prev.kadoPayDetails,
        [name]: value
      }
    }));
  };

  const validateAccountName = (value: string) => {
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
      return 'Account name can only contain letters and numbers';
    }
    return null;
  };

  const checkOrganizationName = async () => {
    setIsCheckingName(true);
    setNameError(null);
    setAccountNameError(null);
    try {
      const [accountNameAvailable, ] = await Promise.all([
        store.checkOrganizationAccountName(formData.displayName, formData.accountName)
      ]);
      
      if (accountNameAvailable) {
        setCurrentStep('integration-choice');
      } else {
        if (!accountNameAvailable) {
          setAccountNameError('This account name is already taken');
        }
      }
    } catch (error) {
      setNameError('Error checking organization details');
    } finally {
      setIsCheckingName(false);
    }
  };

  const handleSubmit = async () => {
    try {
      await store.createOrganization(formData);
      navigate('/organizations');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getProgressValue = () => {
    switch (currentStep) {
      case 'name-check':
        return 20;
      case 'integration-choice':
        return 40;
      case 'kado-pay-setup':
      case 'basic-setup':
        return 60;
      case 'review':
        return 100;
      default:
        return 0;
    }
  };

  const getStepNumber = () => {
    switch (currentStep) {
      case 'name-check':
        return 1;
      case 'integration-choice':
        return 2;
      case 'kado-pay-setup':
      case 'basic-setup':
        return 3;
      case 'review':
        return 4;
      default:
        return 1;
    }
  };

  const handleSimpleIntegration = async () => {
    try {
      const response = await store.createOrganization({
        displayName: formData.displayName,
        accountName: formData.accountName,
        type: 'SIMPLE'
      });
      navigate('/settings'); // Redirect to settings for simple integration
    } catch (error) {
      console.error('Failed to create organization:', error);
    }
  };

  const handlePayIntegration = async () => {
    try {
      const response = await store.createOrganization({
        displayName: formData.displayName,
        accountName: formData.accountName,
        type: 'PAY'
      });
      // Redirect to pay setup page with the new organization's ID
      navigate(`/organizations/${response.organization._id}/setup-pay`);
    } catch (error) {
      console.error('Failed to create organization:', error);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 'name-check':
        return (
          <>
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  type="text"
                  name="displayName"
                  value={formData.displayName}
                  onChange={handleInputChange}
                  placeholder="Enter organization name"
                  isInvalid={!!nameError}
                />
                {nameError && (
                  <Form.Control.Feedback type="invalid">
                    {nameError}
                  </Form.Control.Feedback>
                )}
                <Form.Text className="text-muted">
                  This is your organization's display name
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Account Name</Form.Label>
                <Form.Control
                  type="text"
                  name="accountName"
                  value={formData.accountName}
                  onChange={(e) => {
                    const error = validateAccountName(e.target.value);
                    setAccountNameError(error);
                    handleInputChange(e as React.ChangeEvent<HTMLInputElement>);
                  }}
                  placeholder="Enter account name"
                  isInvalid={!!accountNameError}
                />
                {accountNameError && (
                  <Form.Control.Feedback type="invalid">
                    {accountNameError}
                  </Form.Control.Feedback>
                )}
                <Form.Text className="text-muted">
                  <div className="mt-2">
                    <p className="mb-1">This unique identifier will be used across Kado's systems.</p>
                    <ul className="small ps-3 mb-0">
                      <li>Only letters and numbers allowed</li>
                      <li>No spaces or special characters</li>
                      <li>Cannot be changed later</li>
                    </ul>
                  </div>
                </Form.Text>
              </Form.Group>

              <div className="d-flex justify-content-end">
                <Button 
                  variant="primary" 
                  onClick={checkOrganizationName}
                  disabled={!formData.displayName || !formData.accountName || !!accountNameError || isCheckingName}
                >
                  {isCheckingName ? 'Checking...' : 'Next'}
                </Button>
              </div>
            </Form>
          </>
        );

      case 'integration-choice':
        return (
          <>
            <h4 className="mb-8">Choose Integration Type</h4>
            <div className="row g-4">
              <div className="col-md-6">
                <Card className="h-100 shadow-sm border-0 position-relative">
                  <div className="position-absolute top-0 end-0 m-3">
                    <span className="badge bg-success">Quick Start</span>
                  </div>
                  <Card.Header className="bg-success text-white py-4">
                    <h5 className="mb-0">Simple Integration</h5>
                    <p className="mb-0 mt-2 small">Start integrating immediately</p>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column p-4">
                    <div className="mb-4">
                      <h6 className="mb-3">Features:</h6>
                      <ul className="list-unstyled">
                        <li className="mb-3 d-flex align-items-start">
                          <span className="text-success me-2">✓</span>
                          <div>
                            <strong>Basic API Access</strong>
                            <p className="text-muted small mb-0">Connect to our core API endpoints</p>
                          </div>
                        </li>
                        <li className="mb-3 d-flex align-items-start">
                          <span className="text-success me-2">✓</span>
                          <div>
                            <strong>Basic Reporting</strong>
                            <p className="text-muted small mb-0">View essential transaction data</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="alert alert-success border-success bg-success bg-opacity-10 mb-4">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <i className="fas fa-check-circle fa-2x text-success"></i>
                        </div>
                        <div>
                          <h6 className="mb-1">No Verification Required</h6>
                          <p className="mb-0 small">Start using the integration immediately after setup</p>
                        </div>
                      </div>
                    </div>

                    <Button 
                      variant="outline-secondary"
                      size="lg"
                      className="mt-auto"
                      onClick={handleSimpleIntegration}
                    >
                      Continue with Simple Integration
                      <i className="fas fa-arrow-right ms-2"></i>
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-6">
                <Card className="h-100 shadow-sm border-0 position-relative">
                  <div className="position-absolute top-0 end-0 m-3">
                    <span className="badge bg-primary">Full Features</span>
                  </div>
                  <Card.Header className="bg-primary text-white py-4">
                    <h5 className="mb-0">Kado Pay Integration</h5>
                    <p className="mb-0 mt-2 small">Complete payment processing solution</p>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column p-4">
                    <div className="mb-4">
                      <h6 className="mb-3">Features:</h6>
                      <ul className="list-unstyled">
                        <li className="mb-3 d-flex align-items-start">
                          <span className="text-primary me-2">✓</span>
                          <div>
                            <strong>Payment Processing</strong>
                            <p className="text-muted small mb-0">Accept payments from your customers</p>
                          </div>
                        </li>
                        <li className="mb-3 d-flex align-items-start">
                          <span className="text-primary me-2">✓</span>
                          <div>
                            <strong>Advanced Analytics</strong>
                            <p className="text-muted small mb-0">Detailed reporting and insights</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="alert alert-info border-primary bg-primary bg-opacity-10 mb-4">
                      <div className="d-flex">
                        <div className="me-3">
                          <i className="fas fa-shield-alt fa-2x text-primary"></i>
                        </div>
                        <div>
                          <h6 className="mb-1">Verification Required</h6>
                          <div className="small">
                            <div className="mb-2">
                              <strong>1. KYC Verification</strong>
                              <p className="mb-1">Your account ({formData.personalEmail}) will need to complete identity verification</p>
                            </div>
                            <div>
                              <strong>2. Business Verification (KYB)</strong>
                              <Form.Group className="mt-2">
                                <Form.Label className="small">Business email for KYB instructions:</Form.Label>
                                <Form.Control
                                  type="email"
                                  name="businessEmail"
                                  value={formData.businessEmail}
                                  onChange={handleInputChange}
                                  placeholder="business@company.com"
                                  className="mb-2"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Button 
                      variant="primary"
                      size="lg"
                      className="mt-auto"
                      onClick={handlePayIntegration}
                    >
                      Continue with Kado Pay
                      <i className="fas fa-arrow-right ms-2"></i>
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </>
        );

      case 'basic-setup':
        return (
          <>
            <h4 className="mb-4">Basic Organization Setup</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Personal Email</Form.Label>
                <Form.Control
                  type="email"
                  name="personalEmail"
                  value={formData.personalEmail}
                  onChange={handleInputChange}
                  disabled
                />
                <Form.Text className="text-muted">
                  This is your sign-in email
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  type="text"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Business Email</Form.Label>
                <Form.Control
                  type="email"
                  name="businessEmail"
                  value={formData.businessEmail}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <div className="d-flex justify-content-between">
                <Button 
                  variant="outline-secondary" 
                  onClick={() => setCurrentStep('integration-choice')}
                >
                  Back
                </Button>
                <Button 
                  variant="primary" 
                  onClick={() => setCurrentStep('review')}
                  disabled={!formData.businessName || !formData.businessEmail}
                >
                  Next
                </Button>
              </div>
            </Form>
          </>
        );

      case 'kado-pay-setup':
        return (
          <>
            <h4 className="mb-4">Kado Pay Setup</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Business Email</Form.Label>
                <Form.Control
                  type="email"
                  name="businessEmail"
                  value={formData.businessEmail}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Business Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={formData.kadoPayDetails.address}
                  onChange={handleBusinessDetailsChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={formData.kadoPayDetails.phone}
                  onChange={handleBusinessDetailsChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="url"
                  name="website"
                  value={formData.kadoPayDetails.website}
                  onChange={handleBusinessDetailsChange}
                  placeholder="https://"
                />
              </Form.Group>

              <Alert variant="info">
                Additional verification steps will be required after organization creation
              </Alert>

              <div className="d-flex justify-content-between">
                <Button 
                  variant="outline-secondary" 
                  onClick={() => setCurrentStep('integration-choice')}
                >
                  Back
                </Button>
                <Button 
                  variant="primary" 
                  onClick={() => setCurrentStep('review')}
                >
                  Next
                </Button>
              </div>
            </Form>
          </>
        );

      case 'review':
        return (
          <>
            <h4 className="mb-4">Review Organization Details</h4>
            <div className="mb-4">
              <h5 className="mb-3">Organization Information</h5>
              <p><strong>Name:</strong> {formData.displayName}</p>
              <p><strong>Integration Type:</strong> {formData.setupKadoPay ? 'Kado Pay' : 'Basic'}</p>
              
              {formData.setupKadoPay ? (
                <>
                  <h5 className="mb-3 mt-4">Kado Pay Settings</h5>
                  <p><strong>Google Pay:</strong> {formData.kadoPayDetails.enableGooglePay ? 'Enabled' : 'Disabled'}</p>
                  <p><strong>Address:</strong> {formData.kadoPayDetails.address || 'Not provided'}</p>
                  <p><strong>Phone:</strong> {formData.kadoPayDetails.phone || 'Not provided'}</p>
                  <p><strong>Website:</strong> {formData.kadoPayDetails.website || 'Not provided'}</p>
                </>
              ) : (
                <>
                  <h5 className="mb-3 mt-4">Business Information</h5>
                  <p><strong>Personal Email:</strong> {formData.personalEmail}</p>
                  <p><strong>Business Name:</strong> {formData.businessName}</p>
                  <p><strong>Business Email:</strong> {formData.businessEmail}</p>
                </>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <Button 
                variant="outline-secondary" 
                onClick={() => setCurrentStep(formData.setupKadoPay ? 'kado-pay-setup' : 'basic-setup')}
              >
                Back
              </Button>
              <Button 
                variant="primary" 
                onClick={handleSubmit}
              >
                Create Organization
              </Button>
            </div>
          </>
        );
    }
  };

  return (
    <div className="users-container">
      <div className="content-wrapper">
    <Row>
      <Col>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="section-title mb-0">Create New Organization</h2>
              </div>
              </Col>
             </Row>

      <Card className='card-help'>
      
        <Card.Body>
          {renderStepContent()}
        </Card.Body>
      </Card>
    </div>
    </div>
  );
}); 