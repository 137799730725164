import React from 'react';
import { getCryptoImageUrl, getPaymentMethodIconUrl } from './orderUtils';
import { formatCurrency, formatPaymentMethod } from './formatters';
import { ITransaction } from '../services/interfaces/Transaction';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IOrganization } from 'services/interfaces/Organization';

export const renderDepositCell = (order: ITransaction) => {
  switch (order.type) {
    case "BUY":
      return (
        <>
          {formatCurrency(order.depositAmount || 0)} 
        </>
      );
    case "SELL":
    case "PAY":
      return (
        <>
          <img 
            src={getCryptoImageUrl(order.assetSymbol)}
            alt={order.asset || 'N/A'}
            className="kado-icon d-inline"
          />
          &nbsp;
          {order.depositAmount} {order.assetSymbol || 'N/A'}
        </>
      );
    default:
      return null;
  }
};

export const renderDisbursementCell = (order: ITransaction) => {
  switch (order.type) {
    case "SELL":
    case "PAY":
      return (
        <>
          {formatCurrency(order.disbursementAmount || 0)}
          </>
      )
    case "BUY":
      return (
        <>
          <img 
            src={getCryptoImageUrl(order.assetSymbol)}
            alt={order.asset || 'N/A'}
            className={`kado-icon d-inline ${!order.disbursementAmount ? 'opacity-50' : ''}`}
          />
           &nbsp;{order.disbursementAmount ? (
            `${order.disbursementAmount} ${order.assetSymbol}`
          ) : (
            <small><span className="text-muted">{order.assetSymbol}</span></small>
          )}
        </>
      );
    default:
      return null;
  }
};

export const renderShortId = (id: string, length: number = 4): React.ReactElement => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${id}`}>{id}</Tooltip>}>
      <small className="font-monospace text-muted">{id.slice(-length)}</small>
    </OverlayTrigger>
  );
};

export const renderOrganization = (organization: IOrganization | null | undefined) => {
  
  if (!organization || !organization.name) {
    return <span className="text-muted">N/A</span>;
  }

  return (
        organization.name
  )
};

export const renderOrderSummary = (order: ITransaction): React.ReactNode => {


  switch (order.type) {
    case "BUY":
      return (
        <>
           <img
                      src={getCryptoImageUrl(order.assetSymbol)}
                      alt={order.asset || "N/A"}
                      className="crypto-icon"
                    /> Buy: {formatCurrency(order.depositAmount)} of {order.assetSymbol}
        </>
      );
    case "SELL":
      return (
        <>
          <img
            src={getCryptoImageUrl(order.assetSymbol)}
            alt={order.asset || "N/A"}
            className="crypto-icon"
          />
          Sell: {order.depositAmount} {order.assetSymbol}
        </>
      );
    case "PAY":
      return (
        <>
          Pay: {formatCurrency(order.disbursementAmount)} with {order.assetSymbol}
        </>
      );
    default:
      return null;
  }
};
