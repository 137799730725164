import React from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IUser } from '../services/interfaces/User'; // Adjust the import path as needed

export const renderKycLevel = (user: IUser | undefined) => {
  if (!user) return null;

  if (user.status === "disabled")
    return (
      <Badge bg="danger">
        <FontAwesomeIcon icon={faStar} className="me-1" />
        Inactive
      </Badge>
    );

  switch (user._kycLevel) {
    case "L0":
      return (
        <Badge bg="secondary">
          <FontAwesomeIcon icon={faStar} className="me-1" />
          Not Verified
        </Badge>
      );
    case "L1":
      return (
        <Badge bg="success">
          <FontAwesomeIcon icon={faStarHalfAlt} className="me-1" /> Quick KYC
        </Badge>
      );
    case "L1.5":
    case "L2":
      return (
        <Badge bg="success">
          <FontAwesomeIcon icon={faStar} className="me-1" /> Full KYC
        </Badge>
      );
    default:
      return (
        <Badge bg="light" text="dark">
          <FontAwesomeIcon icon={faTimesCircle} className="me-1" /> N/A
        </Badge>
      );
  }
};
