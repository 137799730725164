import React from 'react';
import KadoIcon from '../components/KadoIcon';
import { getCountryNameFromCode } from './formatters';

export const renderCountryFlag = (countryCode: string | undefined, height='14px') => {
  if (!countryCode) {
    return null;
  }
  
  return (
    <KadoIcon 
      folder="country_flags" 
      name={countryCode} 
      tooltipText={getCountryNameFromCode(countryCode)}
      inline={true}
      height={height}
    />
  );
};
