

export const getCryptoImageUrl = (name: string | undefined): string => {
  if (!name) return '';
  const formattedName = name.toLowerCase().replace(' ', '_');
  return `https://kado-asset-haus.s3.amazonaws.com/images/tokens/light/${formattedName}.png`;
};

export const getPaymentMethodIconUrl = (method?: string) => {
  return `https://kado-asset-haus.s3.amazonaws.com/images/payments/light/${method?.toLowerCase()}.png`;
};